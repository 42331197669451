import React from "react"
import SocialMedia from "./socialmedia"

function Footer() {
  return (
    <div className="footer">
      <hr />
      <div className="horizontal">
        <SocialMedia />
        <p>Designed and developed by Emily Louie</p>
      </div>
    </div>
  )
}

export default Footer
